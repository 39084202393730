<template>
  <el-dialog
    :title="$t('components.dashboard_sidebar.settings')"
    :visible.sync="isVisible"
  >
    <AccountSettingsForm />

    <hr>

    <ChangeEmailForm />

    <hr>

    <ChangePasswordForm />
  </el-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import AccountSettingsForm from '@/components/dashboard/settings/AccountSettingsForm'
import ChangeEmailForm from '@/components/dashboard/settings/ChangeEmailForm'
import ChangePasswordForm from '@/components/dashboard/settings/ChangePasswordForm'

export default {
  components: {
    AccountSettingsForm,
    ChangeEmailForm,
    ChangePasswordForm
  },

  computed: {
    ...mapGetters([
      'dashboardSettingsModalVisible'
    ]),

    isVisible: {
      get () {
        return this.dashboardSettingsModalVisible
      },

      set (value) {
        this.toggleDashboardSettingsModal(value)
      }
    }
  },

  methods: {
    ...mapActions([
      'toggleDashboardSettingsModal'
    ])
  }
}
</script>
