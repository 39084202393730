<template>
  <form @submit.prevent="submitForm">
    <div class="columns is-aligned-center">
      <div class="column is-narrow">
        <croppie-wrapper
          ref="profile_picture"
          v-model="updateProfilePictureForm.profile_picture"
          :settings="croppieSettings"
          :crop-options="croppieSettings.cropOptions"
          is-circle
          text-size="6"
          entity="UsersProfileImage"
          @removed="removeProfilePicture"
          @save="uploadProfilePicture"
        >
          <span>Upload logo</span>
        </croppie-wrapper>
      </div>
    </div>

    <div class="columns is-multiline">
      <div class="column is-6">
        <form-field :validator="$v.updatePreferencesForm.first_name" :label="$t('forms.first_name') ">
          <input
            v-model="updatePreferencesForm.first_name"
            class="input"
            name="first_name"
            type="text"
            @input="$v.updatePreferencesForm.first_name.$touch()"
          >
        </form-field>
      </div>

      <div class="column is-6">
        <form-field :validator="$v.updatePreferencesForm.last_name" :label="$t('forms.last_name') ">
          <input
            v-model="updatePreferencesForm.last_name"
            class="input"
            type="text"
            name="last_name"
            @input="$v.updatePreferencesForm.last_name.$touch()"
          >
        </form-field>
      </div>

      <div class="column is-6">
        <form-field :validator="$v.updatePreferencesForm.country" :label="$t('forms.country')">
          <el-select
            v-model="updatePreferencesForm.country"
            :placeholder="$t('forms.country_select')"
            value-key="id"
            name="country"
            filterable
          >
            <el-option
              v-for="country in countries"
              :key="country.id"
              :label="country.name"
              :value="country"
            />
          </el-select>
        </form-field>
      </div>

      <div class="column is-6">
        <form-field :validator="$v.updatePreferencesForm.metadata_website" :label="$t('forms.website') ">
          <input
            v-model="updatePreferencesForm.metadata_website"
            class="input"
            type="text"
            name="website"
            @input="$v.updatePreferencesForm.metadata_website.$touch()"
          >
        </form-field>
      </div>

      <div class="column is-6">
        <form-field :label="$t('forms.company_name') ">
          <input
            v-model="updatePreferencesForm.metadata_company_name"
            class="input"
            type="text"
            name="company_name"
          >
        </form-field>
      </div>

      <div class="column is-6">
        <form-field :label="$t('forms.occupation') ">
          <el-select
            v-model="updatePreferencesForm.metadata_occupation"
            :placeholder="$t('forms.occupation')"
            name="occupation"
          >
            <el-option
              v-for="occupation in dashboardOccupationTypes"
              :key="occupation"
              :label="$t('pages.dashboard.occupation_types.' + occupation)"
              :value="occupation"
            />
          </el-select>
        </form-field>
      </div>
    </div>

    <div class="field is-flex">
      <v-button
        :loading="isLoading"
        class="is-primary m-l-a"
        data-testid="SavePreferences"
      >
        {{ $t('forms.save') }}
      </v-button>
    </div>
  </form>
</template>

<script>
// import _clone from 'lodash/clone'
import { mapActions, mapGetters } from 'vuex'
import { required, minLength, url } from 'vuelidate/lib/validators'

import { croppieDataFactory, croppieDefaultSettings } from '@hypefactors/shared/js/factories/croppie'

import { hasHttp } from '@/shared/utils'

import Form from '@/services/forms/Form'

const updateProfilePictureForm = new Form({
  profile_picture: { value: croppieDataFactory() }
})

const updatePreferencesForm = new Form({
  first_name: { value: '', rules: { required, min: minLength(2) } },
  last_name: { value: '', rules: { required, min: minLength(2) } },
  country: { value: '', rules: { required } },
  metadata_website: { value: '', rules: { url } },
  metadata_company_name: { value: '', rules: {} },
  metadata_occupation: { value: '', rules: {} }
})

export default {
  validations: {
    updateProfilePictureForm: updateProfilePictureForm.rules(),
    updatePreferencesForm: updatePreferencesForm.rules()
  },

  data () {
    return {
      isLoading: false,

      croppieSettings: croppieDefaultSettings(120, 120),

      updateProfilePictureForm: updateProfilePictureForm,
      updatePreferencesForm: updatePreferencesForm,

      updateProfilePictureCancelToken: null,
      updatePreferencesCancelToken: null
    }
  },

  computed: {
    ...mapGetters([
      'dashboardUser',
      'countries',
      'dashboardOccupationTypes',
      'fetchingCountries'
    ])
  },

  watch: {
    'updatePreferencesForm.metadata_website': function (newVal, oldVal) {
      if (newVal && !hasHttp(newVal)) {
        this.updatePreferencesForm.metadata_website = 'http://' + newVal
      }
    }
  },

  beforeDestroy () {
    this.updateProfilePictureCancelToken && this.updateProfilePictureCancelToken.cancel()

    this.updatePreferencesCancelToken && this.updatePreferencesCancelToken.cancel()
  },

  async mounted () {
    await this.fetchCountries()

    this.mergeUserToForm()
  },

  methods: {
    ...mapActions({
      updateDashboardUserRequest: 'updateDashboardUserRequest',
      fetchCountries: 'fetchCountries'
    }),

    mergeUserToForm () {
      this.updatePreferencesForm.merge({
        first_name: this.dashboardUser.first_name,
        last_name: this.dashboardUser.last_name,
        country: this.dashboardUser.country,
        metadata_website: this.dashboardUser.metadata.website || '',
        metadata_company_name: this.dashboardUser.metadata.company_name || '',
        metadata_occupation: this.dashboardUser.metadata.occupation || ''
      })

      this.updateProfilePictureForm.merge({
        profile_picture: this.dashboardUser.avatar
      })
    },

    async submitForm () {
      this.$v.updatePreferencesForm.$touch()

      if (this.$v.updatePreferencesForm.$error) {
        return
      }

      this.updatePreferencesCancelToken = this.$api.cancelToken()

      this.isLoading = true

      const payload = {
        first_name: this.updatePreferencesForm.first_name,
        last_name: this.updatePreferencesForm.last_name,
        country: this.updatePreferencesForm.country.id, // We just need the id so we reassign it
        metadata: {
          website: this.updatePreferencesForm.metadata_website,
          company_name: this.updatePreferencesForm.metadata_company_name,
          occupation: this.updatePreferencesForm.metadata_occupation
        }
      }

      try {
        await this.updateDashboardUserRequest(payload)

        this.isLoading = false

        this.$notify.success(this.$t('success.successfully_updated_user_settings'))

        this.isVisible = false
      } catch (err) {
        this.isLoading = false

        this.$displayRequestError(err, this.$t('errors.user_could_not_be_updated'))
      }
    },

    async uploadProfilePicture () {
      this.$v.updateProfilePictureForm.$touch()

      if (this.$v.updateProfilePictureForm.$error) {
        return
      }

      this.updateProfilePictureCancelToken = this.$api.cancelToken()

      try {
        await this.$refs.profile_picture.uploadImage()
      } catch (e) {
        if (this.$api.isCancelToken(e)) {
          return
        }

        this.$displayRequestError(e, this.$t('errors.cannot_update_brand_logo'))

        return
      }

      try {
        await this.updateProfilePictureForm.submit('post', 'user/profile-picture', {
          cancelToken: this.updateProfilePictureCancelToken.token
        })

        this.$notify.success(this.$t('success.user_profile_picture_updated'))

        // Fetch the user again so we have logos data updated
        await this.fetchUserRequest()
      } catch (error) {
        if (this.$api.isCancelToken(error)) {
          return
        }

        this.$displayRequestError(error)
      }
    },

    async removeProfilePicture () {
      this.$refs.profile_picture.setIsUploadingStatus(true)

      try {
        await this.$api.delete('user/profile-picture')

        this.$notify.success(this.$t('success.user_profile_picture_removed'))

        // Fetch the user again so we have logos data updated
        await this.fetchUserRequest()
      } catch (err) {
        this.$displayRequestError(err, this.$t('errors.error'))
      } finally {
        this.$refs.profile_picture.setIsUploadingStatus(false)
      }
    }
  }
}
</script>
